// extracted by mini-css-extract-plugin
export var badgePopular = "card-module--badge-popular--2d7a4";
export var badgePreview = "card-module--badge-preview--c49ce";
export var bahamaBlue = "card-module--bahama-blue--43d0a";
export var blue = "card-module--blue--39bef";
export var buttonBlue = "card-module--button-blue--bb641";
export var cardText = "card-module--card-text--cd8d2";
export var container = "card-module--container--4bcd5";
export var descContainer = "card-module--desc-container--1c634";
export var fullPrice = "card-module--full-price--a4552";
export var funcionalidadeContainer = "card-module--funcionalidade-container--52a35";
export var funcionalidades = "card-module--funcionalidades--9b556";
export var funcionalidadesHeader = "card-module--funcionalidades-header--a33a2";
export var grey = "card-module--grey--9a54a";
export var header = "card-module--header--83cad";
export var info = "card-module--info--afe4b";
export var infoIcon = "card-module--info-icon--14c3e";
export var infoIconSmall = "card-module--info-icon-small--93601";
export var notAvailable = "card-module--not-available--095b4";
export var popover = "card-module--popover--cde1a";
export var popular = "card-module--popular--9c10c";
export var preview = "card-module--preview--49f61";
export var price = "card-module--price--7df29";
export var priceContainer = "card-module--price-container--2dbfa";
export var priceContainerSmall = "card-module--price-container-small--d986e";
export var promoDesc = "card-module--promo-desc--7ec5b";
export var scorpionGrassBlue = "card-module--scorpion-grass-blue--74903";
export var separator = "card-module--separator--d9cf5";
export var soon = "card-module--soon--5a307";
export var tryButton = "card-module--try-button--844ba";